<template>
  <sub-page
      :title="$t('documentflow.for_signature')"
      v-model="$store.state.settings.pageData"
      :no-content-condition="itemsFiltered.length === 0"
      :no-content-text="`documentflow.signature.${$store.state.documentflow.displaySigned ? 'no_signed_content' : 'no_content'}`"
      :no-content-icon="$store.state.documentflow.displaySigned ? 'mdi-file-document-multiple-outline' : 'mdi-draw'"
      icon="mdi-draw"
      :loading="loading"
      fill-height
  >
    <template #default>

      <ws-data-table
          v-if="itemsFiltered.length > 0"
          class="mt-5"
          :items="itemsFiltered"
          :headers="headers"
          :row-action="($event) => openEditDocument($event)"
      >

        <template #item.date="{item}">
          <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date , false) }}</h5>
        </template>

        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name }}
            <v-chip v-if="item.is_overdue && !$store.state.documentflow.displaySigned" :color="wsATTENTION" small dark>{{$t('documentflow.signature.overdued')}}</v-chip>
          </h5>
        </template>

        <template #item.code="{item}">
          <h5 class="text-no-wrap">{{ item.code }}</h5>
        </template>

        <template #item.is_signed="{item}">
          <div class="d-flex justify-center">
            <v-icon :color="item.is_signed ? wsACCENT : null">mdi-check-decagram</v-icon>
          </div>
        </template>

      </ws-data-table>
    </template>


    <template #dialog>

      <document-dialog
          v-if="displayDialog"
          v-model="displayDialog"
          :document="entityData"
          @sign="signDocument"
          @decline="declineDocument"
          @upload-extra="uploadExtraFile"
          for-sign
      />

    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import documentDialog from "@/components/pages/businessDashboard/businessDocumentflow/UI/documentDialog";

export default {
  name: "Tasks",
  components : {
    documentDialog
  },
  data() {
    return {
      items : [],
      loading : false,
      entityData : {},
      displayDialog : false,
      showSigned : false,
    }
  },
  computed : {
    itemsFiltered() {
      if ( this.$store.state.documentflow.displaySigned ) {
        return this.items.filter(el => !el.has_extra_protection && el.is_signed || (el.has_extra_protection &&el.is_signed && el.is_extra_signed))
      } else {
        return this.items.filter(el => !el.is_signed   || (el.has_extra_protection &&  !el.is_extra_signed) )
      }

    },
    headers() {
      return [
        { value : 'is_signed' , align : 'd-none' },
        { text : this.$t('Date') , value : 'date' , width : 10 },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Type') , value : 'type_name' , width : 200 },
        { text : this.$t('Code') , value : 'code' , width : 50 },

      ]
    },
  },
  methods : {
    ...mapActions('documentflow', [
      'GET_SIGNATURE_REQUESTS',
      'SIGN_DOCUMENT',
      'UPLOAD_EXTRA_SIGNATURE'
    ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    async downloadFile(item) {

      let result = await this.GET_PRIVATE_FILE(item.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    openEditDocument(item) {

      this.entityData = this.COPY(item)
      this.displayDialog = true
    },
    async uploadExtraFile(data) {
      let result = await this.UPLOAD_EXTRA_SIGNATURE(data)
      if ( !result) {
        return this.ERROR(data)
      }
      let index = this.items.findIndex(el => el.uuid === this.entityData.uuid)
      this.notify(index)
      this.items[index] = result
      this.$store.state.documentflow.signatureRequestsCount--
      this.items = this.COPY(this.items)

      this.notify(this.$t('DocumentSigned') , 'success')

      this.displayDialog = false
    },
    async signDocument(data) {

      this.entityData.resolution = data.resolution

      let result = await this.SIGN_DOCUMENT(this.entityData)
      if ( !result )  {
        return this.ERROR()
      }

      let index = this.items.findIndex(el => el.uuid === this.entityData.uuid)
      this.items[index].is_signed = true
      this.$store.state.documentflow.signatureRequestsCount--
      this.items = this.COPY(this.items)

      if ( this.entityData.has_extra_protection) {
        this.entityData.is_signed = true
        this.entityData = this.COPY(this.entityData)
        return
      }

      this.notify(this.$t('DocumentSigned') , 'success')

      this.displayDialog = false

    },
    declineDocument() {

      this.items = this.items.filter(el => el.uuid !== this.entityData.uuid)
      this.$store.state.documentflow.signatureRequestsCount--
      this.notify(this.$t('DocumentDeclined'))
      this.displayDialog = false
    },

    async initPage() {
      this.loading = true
      let result = await this.GET_SIGNATURE_REQUESTS()
      if ( !result )  {
        this.loading = false
        return this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
      this.loading = false
    }

  },
  beforeMount() {
    this.$store.state.documentflow.displaySigned = false
    this.$store.state.documentflow.displaySignatureTabs = true
  },
  beforeDestroy() {
    this.$store.state.documentflow.displaySignatureTabs = false
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>